import React, { useContext } from "react";
import {
  PrivacyContainer,
  ContHeaderDetail,
  PrivacyTextContainer,
} from "./Privacy.styles.js";
import Header from "../header/Header.js";
import { ContentContainer } from "../../App.styles.js";
import { mySectionContext } from "../Context.js";

export const Privacy = ({ isOpenPrivacity, closePrivacity }) => {
  const { setSectionContact } = useContext(mySectionContext);

  isOpenPrivacity && setSectionContact(false);

  return (
    <PrivacyContainer isOpen={isOpenPrivacity}>
      <ContHeaderDetail onClick={closePrivacity}>
        <Header />
      </ContHeaderDetail>
      <ContentContainer>
        <PrivacyTextContainer>
          <h2>Política de Privacidad</h2>
          <br />
          <p>
            <b>Alex Zambrano Puente</b> le informa sobre su Política de
            Privacidad respecto del tratamiento y protección de los datos de
            carácter personal de los usuarios y clientes que puedan ser
            recabados por la navegación o contratación de servicios a través del
            sitio Web <b>DIRECCIÓN-WEB</b>.
          </p>
          <p>
            En este sentido, el Titular garantiza el cumplimiento de la
            normativa vigente en materia de protección de datos personales,
            reflejada en la Ley Orgánica 3/2018, de 5 de diciembre, de
            Protección de Datos Personales y de Garantía de Derechos Digitales
            (LOPD GDD). Cumple también con el Reglamento (UE) 2016/679 del
            Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a
            la protección de las personas físicas (RGPD).
          </p>
          <p>
            El uso de sitio Web implica la aceptación de esta Política de
            Privacidad así como las condiciones incluidas en el Aviso Legal.
          </p>
          <h3>Identidad del responsable</h3>
          <ul>
            <li>
              Titular: <b>Alex Zambrano Puente</b>
            </li>
            <li>
              <b>DATOS-DEL-COLEGIO-PROFESIONAL</b>
            </li>
            <li>
              NIF/CIF: <b>NIF</b>
            </li>
            <li>
              Domicilio: <b>DIRECCIÓN-POSTAL</b>
            </li>
            <li>
              Correo electrónico: <b>CORREO-ELECTRÓNICO</b>
            </li>
            <li>
              Sitio Web: <b>DIRECCIÓN-WEB</b>
            </li>
          </ul>
          <br />
          <h3>Principios aplicados en el tratamiento de datos</h3>
          <br />
          <p>
            En el tratamiento de tus datos personales, el Titular aplicará los
            siguientes principios que se ajustan a las exigencias del nuevo
            reglamento europeo de protección de datos:
          </p>
          <ul>
            <li>
              <b>Principio de licitud, lealtad y transparencia:</b> El Titular
              siempre requerirá el consentimiento para el tratamiento de tus
              datos personales que puede ser para uno o varios fines específicos
              sobre los que te informará previamente con absoluta transparencia.
            </li>
            <li>
              <b>Principio de minimización de datos:</b> El Titular te
              solicitará solo los datos estrictamente necesarios para el fin o
              los fines que los solicita.
            </li>
            <li>
              <b>Principio de limitación del plazo de conservación:</b> Los
              datos se mantendrán durante el tiempo estrictamente necesario para
              el fin o los fines del tratamiento.
            </li>
            <li>
              <b>Principio de integridad y confidencialidad:</b> Tus datos serán
              tratados de tal manera que su seguridad, confidencialidad e
              integridad esté garantizada. Debes saber que el Titular toma las
              precauciones necesarias para evitar el acceso no autorizado o uso
              indebido de los datos de sus usuarios por parte de terceros.
            </li>
          </ul>
          <br />
          <h3>Obtención de datos personales</h3>
          <br />
          <p>
            Para navegar por <b>SITIO-WEB</b> no es necesario que facilites
            ningún dato personal. Los casos en los que sí proporcionas tus datos
            personales son los siguientes:
          </p>
          <ul>
            <li>
              Al contactar a través de los formularios de contacto o enviar un
              correo electrónico.
            </li>
          </ul>
          <br />
          <h3>Tus derechos</h3>
          <br />
          <p>
            El Titular te informa que sobre tus datos personales tienes derecho
            a:
          </p>
          <ul>
            <li> Solicitar el acceso a los datos almacenados.</li>
            <li> Solicitar una rectificación o la cancelación.</li>
            <li> Solicitar la limitación de su tratamiento.</li>
            <li>Oponerte al tratamiento. </li>
            <li>Solicitar la portabilidad de tus datos.</li>
          </ul>
          <br />
          <p>
            El ejercicio de estos derechos es personal y por tanto debe ser
            ejercido directamente por el interesado, solicitándolo directamente
            al Titular, lo que significa que cualquier cliente, suscriptor o
            colaborador que haya facilitado sus datos en algún momento puede
            dirigirse al Titular y pedir información sobre los datos que tiene
            almacenados y cómo los ha obtenido, solicitar la rectificación de
            los mismos, solicitar la portabilidad de sus datos personales,
            oponerse al tratamiento, limitar su uso o solicitar la cancelación
            de esos datos en los ficheros del Titular.
          </p>
          <p>
            Para ejercitar tus derechos de acceso, rectificación, cancelación,
            portabilidad y oposición tienes que enviar un correo electrónico a
            <b>CORREO ELECTRÓNICO</b> junto con la prueba válida en derecho como
            una fotocopia del D.N.I. o equivalente.
          </p>
          <p>
            Tienes derecho a la tutela judicial efectiva y a presentar una
            reclamación ante la autoridad de control, en este caso, la Agencia
            Española de Protección de Datos, si consideras que el tratamiento de
            datos personales que te conciernen infringe el Reglamento.
          </p>
          <h3>Finalidad del tratamiento de datos personales</h3>
          <br />
          <p>
            Cuando te conectas al sitio Web para mandar un correo al Titular, te
            suscribes a su boletín o realizas una contratación, estás
            facilitando información de carácter personal de la que el
            responsable es el Titular. Esta información puede incluir datos de
            carácter personal como pueden ser tu dirección IP, nombre y
            apellidos, dirección física, dirección de correo electrónico, número
            de teléfono, y otra información. Al facilitar esta información, das
            tu consentimiento para que tu información sea recopilada, utilizada,
            gestionada y almacenada por superadmin.es , sólo como se describe en
            el Aviso Legal y en la presente Política de Privacidad.
          </p>
          <p>
            Los datos personales y la finalidad del tratamiento por parte del
            Titular es diferente según el sistema de captura de información:
          </p>
          <ul>
            <li>
              <b>Formularios de contacto:</b> El Titular solicita datos
              personales entre los que pueden estar: Nombre y apellidos,
              dirección de correo electrónico, número de teléfono y dirección de
              tu sitio Web con la finalidad de responder a tus consultas. Por
              ejemplo, el Titular utiliza esos datos para dar respuesta a tus
              mensajes, dudas, quejas, comentarios o inquietudes que puedas
              tener relativas a la información incluida en el sitio Web, los
              servicios que se prestan a través del sitio Web, el tratamiento de
              tus datos personales, cuestiones referentes a los textos legales
              incluidos en el sitio Web, así como cualquier otra consulta que
              puedas tener y que no esté sujeta a las condiciones del sitio Web
              o de la contratación.
            </li>
          </ul>
          <br />
          <p>
            Existen otras finalidades por las que el Titular trata tus datos
            personales:
          </p>
          <ul>
            <li>
              Para garantizar el cumplimiento de las condiciones recogidas en el
              Aviso Legal y en la ley aplicable. Esto puede incluir el
              desarrollo de herramientas y algoritmos que ayuden a este sitio
              Web a garantizar la confidencialidad de los datos personales que
              recoge.
            </li>
            <li>
              Para apoyar y mejorar los servicios que ofrece este sitio Web.
            </li>
            <li>
              Para analizar la navegación. El Titular recoge otros datos no
              identificativos que se obtienen mediante el uso de cookies que se
              descargan en tu ordenador cuando navegas por el sitio Web cuyas
              caracterísiticas y finalidad están detalladas en la Política de
              Cookies.
            </li>
          </ul>
          <br />
          <h3>Seguridad de los datos personales</h3>
          <br />
          <p>
            Para proteger tus datos personales, el Titular toma todas las
            precauciones razonables y sigue las mejores prácticas de la
            industria para evitar su pérdida, mal uso, acceso indebido,
            divulgación, alteración o destrucción de los mismos.
          </p>
          <p>
            El sitio Web está alojado en <b>PROVEEDOR DE ALOJAMIENTO WEB</b>. La
            seguridad de tus datos está garantizada, ya que toman todas las
            medidas de seguridad necesarias para ello. Puedes consultar su
            política de privacidad para tener más información.
          </p>
          <br />
          <h2>Política de Cookies</h2>
          <br />
          <p>
            Para que este sitio Web funcione correctamente necesita utilizar
            cookies, que es una información que se almacena en tu navegador web.
          </p>
          <h3>Legitimación para el tratamiento de datos</h3>
          <br />
          <p>
            La base legal para el tratamiento de tus datos es: el
            consentimiento.
          </p>
          <p>
            Para contactar con el Titular tienes que aceptar la presente
            Política de Privacidad.
          </p>
          <h3>Categorías de datos personales</h3>
          <br />
          <p>Las categorías de datos personales que trata el Titular son:</p>
          <ul>
            <li>Datos identificativos.</li>
          </ul>
          <h3>Conservación de datos personales</h3>
          <br />
          <p>
            Los datos personales que proporciones al Titular se conservarán
            hasta que solicites su supresión.
          </p>
          <h3>Destinatarios de datos personales</h3>
          <ul>
            <li>
              <b>Google Analytics</b> es un servicio de analítica web prestado
              por Google, Inc., una compañía de Delaware cuya oficina principal
              está en 1600 Amphitheatre Parkway, Mountain View (California), CA
              94043, Estados Unidos (“Google”). Encontrarás más información en:
              <a
                href="https://analytics.google.com"
                target="_blank"
                rel="noreferrer"
              >
                https://analytics.google.com
              </a>{" "}
              Google Analytics utiliza “cookies”, que son archivos de texto
              ubicados en tu ordenador, para ayudar al Titular a analizar el uso
              que hacen los usuarios del sitio Web. La información que genera la
              cookie acerca del uso del sitio Web (incluyendo tu dirección IP)
              será directamente transmitida y archivada por Google en los
              servidores de Estados Unidos.
            </li>
          </ul>
          <br />
          <h3>Navegación Web</h3>
          <br />
          <p>
            Al navegar por <b>SITIO WEB</b> se pueden recoger datos no
            identificativos, que pueden incluir, la dirección IP,
            geolocalización, un registro de cómo se utilizan los servicios y
            sitios, hábitos de navegación y otros datos que no pueden ser
            utilizados para identificarte.
          </p>
          <p>
            El sitio Web utiliza los siguientes servicios de análisis de
            terceros:
          </p>
          <ul>
            <li>Google Analytics</li>
          </ul>
          <br />
          <p>
            El Titular utiliza la información obtenida para obtener datos
            estadísticos, analizar tendencias, administrar el sitio, estudiar
            patrones de navegación y para recopilar información demográfica.
          </p>
          <br />
          <h2>Exactitud y veracidad de los datos personales</h2>
          <br />
          <p>
            Te comprometes a que los datos facilitados al Titular sean
            correctos, completos, exactos y vigentes, así como a mantenerlos
            debidamente actualizados.
          </p>
          <p>
            Como Usuario del sitio Web eres el único responsable de la veracidad
            y corrección de los datos que remitas al sitio exonerando a el
            Titular de cualquier responsabilidad al respecto.
          </p>
          <br />
          <h2>Aceptación y consentimiento</h2>
          <br />
          <p>
            Como Usuario del sitio Web declaras haber sido informado de las
            condiciones sobre protección de datos de carácter personal, aceptas
            y consientes el tratamiento de los mismos por parte de el Titular en
            la forma y para las finalidades indicadas en esta Política de
            Privacidad.
          </p>
          <br />
          <h2>Revocabilidad</h2>
          <br />
          <p>
            Para ejercitar tus derechos de acceso, rectificación, cancelación,
            portabilidad y oposición tienes que enviar un correo electrónico a
            <b>CORREO ELECTRÓNICO</b> junto con la prueba válida en derecho como
            una fotocopia del D.N.I. o equivalente.
          </p>
          <p>
            El ejercicio de tus derechos no incluye ningún dato que el Titular
            esté obligado a conservar con fines administrativos, legales o de
            seguridad.
          </p>
          <br />
          <h2>Cambios en la Política de Privacidad</h2>
          <br />
          <p>
            El Titular se reserva el derecho a modificar la presente Política de
            Privacidad para adaptarla a novedades legislativas o
            jurisprudenciales, así como a prácticas de la industria.
          </p>
          <p>
            Estas políticas estarán vigentes hasta que sean modificadas por
            otras debidamente publicadas.
          </p>
        </PrivacyTextContainer>
      </ContentContainer>
    </PrivacyContainer>
  );
};
