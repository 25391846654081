import styled from "styled-components";

export const MenuMobileConteiner = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  margin-left: 3vh;
`;

export const MenuMobileIcon = styled.svg`
  fill: ${({ theme }) => theme.colors.colorOne};
`;
