import React, { Suspense } from "react";
import { GlobalStyle } from "./GlobalStyles.js";
import { ThemeProvider } from "styled-components";
import { Theme } from "./Theme.js";
import { Container } from "./App.styles.js";
import AppLoader from "./components/AppLoader.js";
import Header from "./components/header/Header.js";
import About from "./components/about/About.js";
import Contact from "./components/contact/Contact.js";
import Footer from "./components/footer/Footer.js";
import SectionContext from "./components/Context.js";
import Services from "./components/about/Services.js";

const Intro = React.lazy(() => import("./components/intro/Intro.js"));
const Works = React.lazy(() => import("./components/works/Works.js"));

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Container>
        <SectionContext>
          <Suspense fallback={<AppLoader />}>
            <Header />
            <Intro />
            <Works />
            <Services />
            <About />
            <Contact />
            <Footer />
          </Suspense>
        </SectionContext>
      </Container>
    </ThemeProvider>
  );
}

export default App;
