import React from "react";
import { MenuMobileIcon } from "./Icons.styles.js";

const ContactIcon = ({ w, h }) => {
  return (
    <MenuMobileIcon viewBox="0 0 512 512" width={w} height={h}>
      <path
        d="M461.913,83.478H50.087C22.467,83.478,0,105.974,0,133.565v244.87c0,27.622,22.498,50.087,50.087,50.087h411.826
			c27.578,0,50.087-22.453,50.087-50.087v-244.87C512,105.984,489.543,83.478,461.913,83.478z M460.563,116.87
			c-8.494,8.494-186.871,186.871-192.757,192.758c-6.527,6.526-17.085,6.526-23.612,0C238.303,303.735,59.927,125.36,51.437,116.87
			H460.563z M33.391,377.085V146.046l115.519,115.519L33.391,377.085z M62.567,395.13l109.954-109.954l48.061,48.061
			c19.526,19.528,51.304,19.529,70.834,0l48.061-48.061L449.432,395.13H62.567z M478.609,377.085L363.089,261.565l115.519-115.519
			V377.085z"
      />
    </MenuMobileIcon>
  );
};

export default ContactIcon;
