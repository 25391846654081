import React, { useRef, useEffect, useContext } from "react";
import { AboutContainer, ImagenPerfil, InfoContainer } from "./About.styles.js";
import { ContentContainer } from "../../App.styles.js";
import Profile from "./Profile.js";
import { useMediaQuery } from "../../hooks/useMediaQuery.js";
import imgPerfil from "../../assets/imgs/alexPerfil.jpg";
import { mySectionContext } from "../Context.js";

const About = () => {
  const isDesktop = useMediaQuery("(min-width: 640px)");
  const sectionRef = useRef();
  const { setSectionAbout } = useContext(mySectionContext);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSectionAbout(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
  }, []);

  return (
    <>
      <AboutContainer id="perfil">
        <ContentContainer>
          {!isDesktop && <Profile />}
          <ImagenPerfil src={imgPerfil} alt="Alex Zambrano" id="logo" ref={sectionRef} />
          <InfoContainer>{isDesktop && <Profile />}</InfoContainer>
        </ContentContainer>
      </AboutContainer>
    </>
  );
};

export default About;
