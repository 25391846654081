import React from "react";
import { ErrorContainer } from "./FormError.styles.js";
import { useSpring } from "react-spring";
import ErrorIcon from "../icons/ErrorIcon.js";

const FormError = ({ message }) => {
  const ErrorProps = useSpring({
    marginTop: message ? "0px" : "-10px",
    from: {
      marginTop: "-10px",
    },
  });
  return (
    <ErrorContainer style={ErrorProps}>
      <ErrorIcon w={12} h={12} c="#a42d27" /> {message}
    </ErrorContainer>
  );
};

export default FormError;
