import React, { useState, createContext } from "react";

export const mySectionContext = createContext();

export default function SectionContext({ children }) {
  const [sectionAbout, setSectionAbout] = useState(false);
  const [sectionWorks, setSectionWorks] = useState(false);
  const [sectionContact, setSectionContact] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  return (
    <mySectionContext.Provider
      value={{
        sectionAbout,
        setSectionAbout,
        sectionWorks,
        setSectionWorks,
        sectionContact,
        setSectionContact,
        isDesktop,
        setIsDesktop,
      }}
    >
      {children}
    </mySectionContext.Provider>
  );
}
