import React, { useRef, useEffect, useContext } from "react";
import { mySectionContext } from "../Context.js";
import { TitleAbout } from "./About.styles.js";

const Profile = () => {
  const sectionRef = useRef();
  const sectionRef2 = useRef();
  const { setSectionAbout } = useContext(mySectionContext);
  let entry, entry2;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entry = entries[0];
    });

    const observer2 = new IntersectionObserver((entries) => {
      entry2 = entries[0];
    });

    entry && entry2 ? setSectionAbout(true) : setSectionAbout(false);

    observer.observe(sectionRef.current);
    observer2.observe(sectionRef2.current);
  }, []);

  return (
    <>
      <h3>Perfil</h3>
      <TitleAbout ref={sectionRef}>Alex</TitleAbout>
      <p>
        Con más de 30 años como maestro carpintero, he realizado variedad de proyectos en los que la
        madera ha sido la protagonista. He resuelto las necesidades de cada uno de mis clientes,
        obteniendo un resultado para toda la vida, tanto estético como funcional, con el objetivo de
        crear piezas y muebles que sean esenciales en cualquier espacio.
      </p>
      <p>
        Durante estas tres décadas, he desarrollado un sistema de trabajo que me permite resaltar
        las características únicas y nobles de la madera con precisión y eficiencia.
      </p>
      <p ref={sectionRef2}>
        La confianza que mis clientes han depositado en mis manos a lo largo de los años es algo que
        valoro enormemente, y espero seguir contando con su apoyo en el futuro. Mi compromiso es
        seguir brindando resultados duraderos y satisfactorios, con la garantía de que cada pieza
        que creo dejará una impresión perdurable en cualquier ambiente que ocupe.
      </p>
    </>
  );
};

export default Profile;
