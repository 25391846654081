import styled from "styled-components";

export const FooterContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ccc;
`;

export const ContentContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 12vh;
  font-size: 0.8rem;
  //color: ${({ theme }) => theme.colors.backColor}; ;
`;

export const InfoFooter = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  display: block;
  padding-bottom: 2rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.txtColor};
  }

  a:hover {
    text-decoration: underline;
  }
`;
