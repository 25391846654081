import styled from "styled-components";

export const FormContact = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  @media (min-width: 640px) {
    width: 100%;
  }
`;

export const FormInput = styled.input`
  display: block;
  padding: 0.75em;
  border: thin solid ${({ theme }) => theme.colors.backColorTwo};
  margin-top: 1rem;
  outline: none;
  width: 100%;
  font-size: 1rem;
  line-height: 1.6rem;
  background-color: transparent;
`;

export const FormInputArea = styled.textarea`
  display: block;
  padding: 0.75em;

  border: thin solid ${({ theme }) => theme.colors.backColorTwo};
  margin-top: 1rem;
  outline: none;
  width: 100%;
  font-size: 1rem;
  line-height: 1.6rem;
  background-color: transparent;
  font-family: inherit;
  resize: none;
`;

export const FormContactContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  @media (min-width: 640px) {
    width: 50%;
  }
`;

export const InputSubmit = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.backColorTwo};
  margin-top: 1rem;
  border: none;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.colorOne};
`;

export const Message = styled.div`
  font-size: 0.75rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.okColor};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
