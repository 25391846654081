import styled from "styled-components";
import { animated } from "react-spring";

export const HeaderContainer = styled.header`
  position: fixed;
  bottom: 0;
  z-index: 500;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 12vh;
  background-color: ${({ theme }) => theme.colors.colorThree};
  box-shadow: rgba(17, 17, 26, 0.1) 0px -3px 0px;

  @media (min-width: 640px) {
    top: 0;
    height: 10vh;
    padding: 0 2rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 3px 0px;
  }
`;

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 1200px;

  @media (min-width: 640px) {
    flex-direction: row;
  }
`;

export const LogoHeader = styled(animated.img)`
  width: 0vh;
  height: 12vh;
  margin-right: 3vh;
  border: 1vh solid ${({ theme }) => theme.colors.colorThree};
  box-shadow: rgba(17, 17, 26, 0.1) 0px -3px 0px;

  @media (min-width: 640px) {
    width: 12vh;
    height: 12vh;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 3px 0px;
  }
`;
