import styled from "styled-components";

export const ContactContainer = styled.section`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.backColor};

  @media (min-width: 640px) {
    padding-top: 8rem;
  }
`;

export const ContentContact = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0rem 3rem 3rem 3rem;

  @media (min-width: 640px) {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0rem 2rem 8rem 2rem;
  }
`;

export const TitleContact = styled.h2`
  margin: 1rem 0 1rem 0;
`;

export const InfoContact = styled.div`
  height: 100%;
  width: 100%;
  @media (min-width: 640px) {
    width: 50%;
  }
`;

export const DataContact = styled.div`
  padding-bottom: 2rem;
  @media (min-width: 640px) {
    padding-bottom: 0rem;
  }
`;

export const DataContactItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.3rem 0;
`;

export const DataContactItemText = styled.span`
  margin-left: 0.5rem;
`;
