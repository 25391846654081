import React, { useState } from "react";
import { useSpring } from "react-spring";
import { useMediaQuery } from "../../hooks/useMediaQuery.js";
import {
  HeaderContainer,
  NavBarContainer,
  LogoHeader,
} from "./Header.styles.js";
import MenuDesktop from "./MenuDesktop.js";
import MenuMobile from "./MenuMobile.js";
import logo from "../../assets/imgs/zambrano_small.jpg";

const Header = () => {
  const [on, setOn] = useState(false);

  const isDesktop = useMediaQuery("(min-width: 640px)");

  let upThreshold = isDesktop ? 10 : -70;
  let downThreshold = -120;

  const moveDesktop = useSpring({ y: on ? 21 : -120 });
  const moveMobile1 = useSpring({
    to: [
      {
        y: on ? -26 : 100,
      },
      { width: on ? "12vh" : "0vh" },
    ],
  });

  const moveMobile1B = useSpring({
    to: [
      { width: on ? "12vh" : "0vh" },
      {
        y: on ? -26 : 100,
      },
    ],
  });

  window.addEventListener("scroll", () => {
    let scrollY = document.querySelector("#logo").getBoundingClientRect().top;

    if (scrollY < upThreshold) {
      setOn(true);
    } else if (scrollY > downThreshold) {
      setOn(false);
    }
  });

  return (
    <HeaderContainer>
      <NavBarContainer>
        <a href="#intro">
          <LogoHeader
            style={isDesktop ? moveDesktop : on ? moveMobile1B : moveMobile1}
            src={logo}
            alt="Alex Zambrano"
          />
        </a>
        {isDesktop ? <MenuDesktop /> : <MenuMobile />}
      </NavBarContainer>
    </HeaderContainer>
  );
};

export default Header;
