import React, { useEffect, useRef, useContext } from "react";
import ContactIcon from "../icons/ContactIcon";
import LocationIcon from "../icons/LocationIcon";
/* import WhatsappIcon from "../icons/WhatsappIcon"; */
import {
  ContactContainer,
  TitleContact,
  ContentContact,
  InfoContact,
  DataContact,
  DataContactItem,
  DataContactItemText,
} from "./Contact.styles";
import ContactForm from "./ContactForm.js";
import { mySectionContext } from "../Context.js";

const Contact = () => {
  const sectionRef = useRef();
  const { setSectionContact } = useContext(mySectionContext);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSectionContact(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
  }, []);

  return (
    <ContactContainer id="contacto">
      <ContentContact>
        <InfoContact>
          <TitleContact>Contacto</TitleContact>
          <DataContact>
            {/* <DataContactItem>
              <WhatsappIcon w={18} h={18} />
              <DataContactItemText>+34 640 35 09 995</DataContactItemText>
            </DataContactItem> */}
            <DataContactItem>
              <ContactIcon w={18} h={18} />
              <DataContactItemText>alex@carpinteriazambrano.com</DataContactItemText>
            </DataContactItem>
            <DataContactItem ref={sectionRef}>
              <LocationIcon w={18} h={18} />
              <DataContactItemText>Morata de Tajuña - Madrid</DataContactItemText>
            </DataContactItem>
          </DataContact>
        </InfoContact>
        <ContactForm />
      </ContentContact>
    </ContactContainer>
  );
};

export default Contact;
