import React, { useContext } from "react";
import ContactIcon from "../icons/ContactIcon.js";
import { MenuMobileConteiner } from "./MenuMobile.styles.js";
import ProfileIcon from "../icons/ProfileIcon.js";
import WorksIcon from "../icons/WorksIcon.js";
import Selector from "./Selector.js";
import { mySectionContext } from "../Context.js";

const MenuMobile = () => {
  const { sectionAbout, sectionWorks, sectionContact } =
    useContext(mySectionContext);

  return (
    <MenuMobileConteiner>
      <a href="#perfil">
        <ProfileIcon w={24} h={24} />
        <Selector isActive={sectionAbout} />
      </a>
      <a href="#trabajos">
        <WorksIcon w={24} h={24} />
        <Selector isActive={sectionWorks} />
      </a>
      <a href="#contacto">
        <ContactIcon w={24} h={24} />
        <Selector isActive={sectionContact} />
      </a>
    </MenuMobileConteiner>
  );
};

export default MenuMobile;
