import React, { useRef, useEffect, useContext } from "react";
import { mySectionContext } from "../Context.js";
import { ServicesContainer, SubTitle } from "./About.styles.js";

const Services = () => {
  const sectionRef = useRef();
  const { setSectionAbout } = useContext(mySectionContext);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setSectionAbout(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
  }, []);

  return (
    <div
      style={{
        alignItems: "center",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FFF",
      }}
    >
      <ServicesContainer>
        <SubTitle ref={sectionRef}>Servicios ofrecidos</SubTitle>
        <p>
          Ofrecemos una amplia gama de servicios de carpintería personalizados para satisfacer las
          necesidades específicas de cada espacio y cliente.
        </p>
        Nuestros servicios incluyen
        <ul>
          <li>
            <b>Diseño y fabricación de muebles a medida:</b> Creamos muebles adaptados a las
            dimensiones y requisitos exactos del espacio y del usuario, garantizando una solución
            perfecta.
          </li>
          <li>
            <b>Revestimiento de armarios y vestidores:</b> Proporcionamos revestimientos interiores
            y exteriores de alta calidad para armarios y vestidores, optimizando el espacio de
            almacenamiento y mejorando la apariencia estética.
          </li>
          <li>
            <b>Instalación y desmontaje de puertas y mobiliario de madera:</b> Contamos con
            experiencia en la instalación y desmontaje de puertas y mobiliario de madera,
            garantizando un ajuste preciso y duradero.
          </li>
          <li>
            <b>Reparación de carpintería en madera:</b> Ofrecemos servicios de reparación y
            restauración de carpintería en madera, devolviendo su belleza y funcionalidad original.
          </li>
          <li>
            <b>Muebles de baño a medida:</b> Creamos muebles personalizados para baños, combinando
            estilo y funcionalidad para aprovechar al máximo el espacio disponible.
          </li>
          <li>
            <b>Muebles de cocina a medida:</b> Diseñamos y fabricamos muebles de cocina a medida,
            teniendo en cuenta las necesidades y preferencias individuales, creando espacios
            prácticos y estéticamente atractivos.
          </li>
          <li>
            <b>Elementos constructivos en madera:</b> Realizamos proyectos de carpintería que
            incluyen barandillas, escaleras, acabados de suelos y otros elementos constructivos en
            madera, aportando calidez y elegancia a los espacios.
          </li>
          <li>
            <b>Montaje y desmontaje de stands y espacios efímeros:</b> Brindamos servicios de
            montaje y desmontaje de stands y espacios efímeros en tiendas y ferias, asegurando una
            presentación impactante y funcional.
          </li>
        </ul>
      </ServicesContainer>
    </div>
  );
};

export default Services;
