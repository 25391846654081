import React from "react";
import { SelectoBar } from "./Selector.styles.js";
import { useSpring } from "react-spring";
import { useMediaQuery } from "../../hooks/useMediaQuery.js";

const Selector = ({ isActive }) => {
  const isDesktop = useMediaQuery("(min-width: 640px)");

  let puntoA, puntoB;

  if (isDesktop) {
    puntoA = "5vh";
    puntoB = "7vh";
  } else {
    puntoA = "10vh";
    puntoB = "12vh";
  }

  const BarProps = useSpring({
    top: isActive ? puntoA : puntoB,
    from: {
      top: puntoB,
    },
  });

  return <SelectoBar style={BarProps}> </SelectoBar>;
};

export default Selector;
