import React, { useContext } from "react";
import ContactIcon from "../icons/ContactIcon.js";
import ProfileIcon from "../icons/ProfileIcon.js";
import WorksIcon from "../icons/WorksIcon.js";
import Selector from "./Selector.js";
import {
  MenuDesktopConteiner,
  NavDesktop,
  ButtonMenu,
  TxtMenu,
} from "./MenuDesktop.styles.js";
import { mySectionContext } from "../Context.js";

const MenuDesktop = () => {
  const { sectionAbout, sectionWorks, sectionContact } =
    useContext(mySectionContext);

  return (
    <MenuDesktopConteiner>
      <NavDesktop>
        <a href="#perfil">
          <ButtonMenu>
            <ProfileIcon w={24} h={24} />
            <TxtMenu>Perfil</TxtMenu>
            <Selector isActive={sectionAbout} />
          </ButtonMenu>
        </a>
        <a href="#trabajos">
          <ButtonMenu>
            <WorksIcon w={24} h={24} />
            <TxtMenu>Trabajos</TxtMenu>
            <Selector isActive={sectionWorks} />
          </ButtonMenu>
        </a>
        <a href="#contacto">
          <ButtonMenu>
            <ContactIcon w={24} h={24} />
            <TxtMenu>Contacto</TxtMenu>
            <Selector isActive={sectionContact} />
          </ButtonMenu>
        </a>
      </NavDesktop>
    </MenuDesktopConteiner>
  );
};

export default MenuDesktop;
