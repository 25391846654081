import styled from "styled-components";

export const AboutContainer = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #fff;
`;

export const ServicesContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  background-color: #fff;
  margin: 0rem 3rem 0rem 3rem;
  max-width: 1200px;

  @media (min-width: 640px) {
    max-width: 1200px;
    margin: 0rem 2rem 0rem 2rem;
  }
`;

export const ImagenPerfil = styled.img`
  width: 100%;
  margin-top: 2rem;
  filter: grayscale(100%);

  @media (min-width: 640px) {
    width: 25vw;
    height: auto;
    margin-top: 0;
  }
`;

export const InfoContainer = styled.div`
  @media (min-width: 640px) {
    margin-left: 4rem;
  }
`;

export const TitleAbout = styled.h2`
  margin: 2rem 0 1rem 0;
`;

export const SubTitle = styled.h3`
  margin: 2rem 0 2rem 0;
`;
