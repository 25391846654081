import styled from "styled-components";
import { animated } from "react-spring";

let vh = window.innerHeight;

export const AppLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  height: ${vh}px;
  background-color: ${({ theme }) => theme.colors.colorThree};
`;

export const IconLoaderContainer = styled.div``;

export const IconLoader = styled(animated.div)`
  position: absolute;
`;

export const TextLoaderContainer = styled.div`
  margin: 1rem;
  font-weight: bolder;
  font-family: ${({ theme }) => theme.fonts.logoFont}, sans-serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.colorOne};
`;
