import styled from "styled-components";
import { animated } from "react-spring";

export const SelectoBar = styled(animated.div)`
  position: absolute;
  top: 7vh;
  width: 25px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.colorOne};

  @media (min-width: 640px) {
    width: 120px;
  }
`;
