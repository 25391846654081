import React from "react";
import { useSpring } from "react-spring";
import {
  AppLoaderContainer,
  IconLoader,
  IconLoaderContainer,
  TextLoaderContainer,
} from "./AppLoader.styles.js";
import WorksIcon from "./icons/WorksIcon.js";

const AppLoader = () => {
  const loaderProp = useSpring({
    loop: true,
    to: [
      { x: 0, y: -160 },
      { x: -30, y: -130 },
    ],
    from: { x: -30, y: -130 },
  });

  return (
    <AppLoaderContainer>
      <IconLoaderContainer>
        <IconLoader style={loaderProp}>
          <WorksIcon w={64} h={64} />
        </IconLoader>
        <TextLoaderContainer></TextLoaderContainer>
      </IconLoaderContainer>
    </AppLoaderContainer>
  );
};

export default AppLoader;
