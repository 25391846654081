import React from "react";
import { MenuMobileIcon } from "./Icons.styles.js";

const WorksIcon = ({ w, h }) => {
  return (
    <MenuMobileIcon viewBox="0 0 512 512" width={w} height={h}>
      <path
        d="M497.337,134.341L402.786,39.79c-19.549-19.551-51.362-19.554-70.914,0c-8.524,8.523-309.324,309.325-317.498,317.497
			c-19.166,19.167-19.166,50.353,0,69.521l55.17,55.17c8.91,8.912,24.217,5.131,27.841-7.056l12.532-42.159l51.756-4.712
			c6.811-0.621,12.557-5.327,14.506-11.883l12.532-42.159l51.756-4.712c6.811-0.621,12.557-5.327,14.506-11.883l12.532-42.159
			l51.756-4.712c6.811-0.621,12.557-5.327,14.506-11.883l12.217-41.101l38.877-0.114c19.62,14.476,47.464,12.826,65.202-4.914
			l47.275-47.275C516.888,185.704,516.888,153.893,497.337,134.341z M333.455,224.163c-7.379,0.022-13.87,4.88-15.972,11.952
			l-12.532,42.159l-51.756,4.712c-6.811,0.621-12.557,5.327-14.506,11.883l-12.532,42.159l-51.756,4.712
			c-6.811,0.621-12.557,5.327-14.506,11.883l-12.532,42.159l-51.756,4.714c-6.811,0.621-12.557,5.327-14.506,11.883l-7.762,26.116
			L38.013,403.17c-6.133-6.133-6.133-16.113,0-22.246l234.766-234.766l22.899,22.899l55.055,55.055L333.455,224.163z M473.7,181.616
			l-47.275,47.275c-6.519,6.518-17.12,6.516-23.639,0l-106.37-106.369l59.094-59.094c6.533-6.533,17.106-6.533,23.639,0
			l94.551,94.551C480.216,164.495,480.216,175.099,473.7,181.616z"
      />
      <path
        d="M426.422,157.978l-47.274-47.275c-6.526-6.526-17.11-6.526-23.638,0s-6.528,17.11,0,23.638l47.275,47.275
			c3.264,3.264,7.542,4.896,11.818,4.896c4.277,0,8.556-1.632,11.818-4.896C432.95,175.089,432.95,164.505,426.422,157.978z"
      />
    </MenuMobileIcon>
  );
};

export default WorksIcon;
