import styled from "styled-components";

export const PrivacyContainer = styled.div`
  ${({ isOpen }) => (isOpen ? `display:flex;` : `display:none;`)};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  justify-content: flex-reverse;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backColor};

  @media (min-width: 640px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ContHeaderDetail = styled.div`
  width: 100%;
`;

export const PrivacyTextContainer = styled.div`
  padding: 0rem 4rem 4rem 4rem;
  margin: 0rem 0rem;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 0.8rem;
  @media (min-width: 640px) {
    padding: 2rem 4rem;
    margin: 1rem 0rem;
  }
`;
