import React from "react";
import { MenuMobileIcon } from "./Icons.styles.js";

const SuccessIcon = ({ w, h, c }) => {
  return (
    <MenuMobileIcon width={w} height={h}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 12A6 6 0 106 0a6 6 0 000 12zm2.576-7.02a.75.75 0 00-1.152-.96L5.45 6.389l-.92-.92A.75.75 0 003.47 6.53l1.5 1.5a.75.75 0 001.106-.05l2.5-3z"
        fill={c}
      />
    </MenuMobileIcon>
  );
};

export default SuccessIcon;
