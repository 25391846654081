import React from "react";
import { MenuMobileIcon } from "./Icons.styles.js";

const ErrorIcon = ({ w, h, c }) => {
  return (
    <MenuMobileIcon width={w} height={h} viewBox="0 0 12 12">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 6A6 6 0 110 6a6 6 0 0112 0zM5 3a1 1 0 012 0v3a1 1 0 01-2 0V3zm1 5a1 1 0 100 2 1 1 0 000-2z"
        fill={c}
      />
    </MenuMobileIcon>
  );
};

export default ErrorIcon;
