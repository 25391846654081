import React from "react";
import { MenuMobileIcon } from "./Icons.styles.js";

const LocationIcon = ({ w, h }) => {
  return (
    <MenuMobileIcon viewBox="0 0 32 32" width={w} height={h}>
      <path d="M16,2A10,10,0,0,0,6,12c0,5.15,8.26,16.34,9.2,17.6a1,1,0,0,0,1.6,0C17.74,28.34,26,17.15,26,12A10,10,0,0,0,16,2Zm0,25.3C13.2,23.39,8,15.43,8,12a8,8,0,0,1,16,0C24,15.43,18.8,23.39,16,27.3Z" />
      <path d="M16,7a5,5,0,1,0,5,5A5,5,0,0,0,16,7Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,16,15Z" />
    </MenuMobileIcon>
  );
};

export default LocationIcon;
