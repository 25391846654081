import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1940px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6rem 3rem 3rem 3rem;

  @media (min-width: 640px) {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0rem 2rem 0rem 2rem;
    padding-top: 10rem;
  }
`;

export const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.colorThree};
`;
