const Theme = {
  colors: {
    colorOne: "#0d2159",
    colorTwo: "#BFBFFE",
    colorThree: "#e3ac14",
    colorFour: "#F1F1E6",
    txtColor: "#333",
    txtColorLight: "#999",
    backColor: "#fff",
    backColorTwo: "#c2caca",
    errorColor: "#a42d27",
    okColor: "#198754",
  },
  fonts: {
    logoFont: "Roboto Flex",
    txtFont: "Raleway",
    titleFont: "Roboto Flex",
  },
};

export { Theme };
