import styled from "styled-components";

export const MenuDesktopConteiner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  overflow: hidden;
`;

export const NavDesktop = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonMenu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 4rem;
`;

export const TxtMenu = styled.span`
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.colorOne};
`;
