import React from "react";
import { ContentContainer } from "../../App.styles.js";
import { FooterContainer, InfoFooter, ContentContainerFooter } from "./Footer.styles.js";
import { useMediaQuery } from "../../hooks/useMediaQuery.js";
import { useModal } from "../../hooks/useModal.js";
import { Privacy } from "./Privacy.js";

const Footer = () => {
  const [isOpenPrivacity, openPrivacity, closePrivacity] = useModal(false);
  const isDesktop = useMediaQuery("(min-width: 640px)");

  const handleClick = (e) => {
    e.preventDefault();
    openPrivacity();
  };

  isOpenPrivacity
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "visible");

  return (
    <FooterContainer>
      <ContentContainer>
        <ContentContainerFooter>
          <InfoFooter>
            Alex Zambrano Puente - 2024{/*  {isDesktop ? " | " : <br />}{" "} */}
            {/* <a href="#" onClick={handleClick}>
              Política de Privacidad
            </a> */}
          </InfoFooter>
          <InfoFooter>
         {/*    Desarrollo:{" "}
            <a href="http://thepixelfaces.com" target="_blank" rel="noreferrer">
              Pixel Faces
            </a> */}
          </InfoFooter>
        </ContentContainerFooter>
      </ContentContainer>
      <Privacy isOpenPrivacity={isOpenPrivacity} closePrivacity={closePrivacity} />
    </FooterContainer>
  );
};

export default Footer;
