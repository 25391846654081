import React from "react";
import { useForm } from "../../hooks/useForm.js";
import SuccessIcon from "../icons/SuccessIcon.js";
import {
  FormContact,
  FormInput,
  FormInputArea,
  FormContactContainer,
  InputSubmit,
  Message,
  LoaderContainer,
} from "./ContactForm.styles";
import FormError from "./FormError.js";
import Loader from "./Loader/Loader.js";

const initialForm = {
  name: "",
  email: "",
  comments: "",
};

const validationsForm = (form) => {
  let errors = {};

  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexComments = /^.{1,500}$/;

  if (!form.name.trim()) {
    errors.name = "El campo 'Nombre' es requerido";
  } else if (!regexName.test(form.name.trim())) {
    errors.name = "El campo 'Nombre' solo acepta letras";
  } else if (!form.email.trim()) {
    errors.email = "El campo 'Email' es requerido";
  } else if (!regexEmail.test(form.email.trim())) {
    errors.email = "El campo 'Email' es incorrecto";
  } else if (!form.comments.trim()) {
    errors.comments = "El campo 'Comentarios' es requerido";
  } else if (!regexComments.test(form.comments.trim())) {
    errors.comments =
      "El campo 'Comentario' no debe exceder los 500 caracteres";
  }

  return errors;
};

const ContactForm = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);

  return (
    <FormContactContainer>
      <h3>Envía un comentario:</h3>
      <FormContact>
        <form onSubmit={handleSubmit}>
          <FormInput
            type="text"
            name="name"
            placeholder="Nombre"
            value={form.name}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.name && <FormError message={errors.name} />}
          <FormInput
            type="email"
            name="email"
            placeholder="Email"
            value={form.email}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
          {errors.email && <FormError message={errors.email} />}
          <FormInputArea
            name="comments"
            cols={50}
            rows={5}
            placeholder="Comentarios"
            value={form.comments}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          ></FormInputArea>
          {errors.comments && <FormError message={errors.comments} />}
          <InputSubmit type="submit" value="Enviar" />
        </form>

        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {response && (
          <Message>
            <SuccessIcon w={12} h={12} c="#198754" /> Los datos han sido
            enviados.
          </Message>
        )}
      </FormContact>
    </FormContactContainer>
  );
};

export default ContactForm;
