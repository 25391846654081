import styled from "styled-components";
import { animated } from "react-spring";

export const ErrorContainer = styled(animated.div)`
  position: realtive;
  font-size: 0.8rem;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.errorColor};
  margin: 0 0 1rem 0;
`;
