import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Roboto Flex', sans-serif;
  line-height: 1.6;
  scroll-behavior: smooth;
}

*,
*::after,
*::before {
   box-sizing: inherit;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.txtFont}, sans-serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.txtColor};
  letter-spacing:1px;
  background-color: ${({ theme }) => theme.colors.backColor};
  scroll-behavior: smooth;
}

p{  
  margin: 0 0 2rem 0;
  padding: 0;
}

h2{
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.titleFont}, sans-serif;
  font-size:1.8rem;
  color: ${({ theme }) => theme.colors.colorOne};
}

h3{
  margin: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.txtFont}, sans-serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.txtColorLight};
}

h4{
  margin: 0 0 1rem 0;
  padding: 0;
  font-family: ${({ theme }) => theme.fonts.txtFont}, sans-serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.colorOne};
}

li {
  list-style: none;
  margin-bottom: 1rem;
}

li::before {
  content: "■ ";
  color: ${({ theme }) => theme.colors.colorThree}; 
}

a{
  text-decoration:none;
}

`;
